import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";

// HTTP link configuration
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  fetchOptions: { method: "POST" },
});

// Authorization link configuration
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({ headers: { Authorization: "guest" } });
  return forward(operation);
});

// Create Apollo Client
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
