import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

function spaceHtml(target: Element, whitespace: boolean): void {
  const space = target.querySelectorAll('.whitespace');
  if (space.length && whitespace) {
    space.forEach(item => item.outerHTML = ' ');
  }
}

export const splittingChar = (target: Element, whitespace = true): HTMLElement[] => {
  const el = Splitting({ target: target, whitespace: false })[0] as unknown as HTMLElement[];
  spaceHtml(target, whitespace);
  return el;
};

export const splittingWords = (target: Element, whitespace = true): HTMLElement[] => {
  const el = Splitting({ target: target, by: 'words' })[0] as unknown as HTMLElement[];
  spaceHtml(target, whitespace);
  return el;
};

export const splittingLine = (target: Element, whitespace = true): HTMLElement[] => {
  const el = Splitting({ target: target, by: 'lines' })[0] as unknown as HTMLElement[];
  spaceHtml(target, whitespace);
  return el;
};

export const splittingItems = (target: Element, matching: string | null = null): HTMLElement[] => {
  return Splitting({ target, by: 'items', matching }) as unknown as HTMLElement[];
};

export const splittingGrid = (target: Element, matching: string | null = null): HTMLElement[] => {
  return Splitting({ target, by: 'grid', matching }) as unknown as HTMLElement[];
};

const dsnSplitting: {
  Char: (target: Element, whitespace?: boolean) => HTMLElement[];
  Words: (target: Element, whitespace?: boolean) => HTMLElement[];
  Lines: (target: Element, whitespace?: boolean) => HTMLElement[];
  Items: (target: Element, matching?: string | null) => HTMLElement[];
  Grid: (target: Element, matching?: string | null) => HTMLElement[];
} = {
  Char: splittingChar,
  Words: splittingWords,
  Lines: splittingLine,
  Items: splittingItems,
  Grid: splittingGrid,
};

export default dsnSplitting;
