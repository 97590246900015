import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeModeState {
  current: string;
}

const initialState: ThemeModeState = {
  current: "light",
};

export const themeModeSlice = createSlice({
  name: "themeMode",
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setThemeMode } = themeModeSlice.actions;

const themeModeReducer = themeModeSlice.reducer;

export default themeModeReducer;
