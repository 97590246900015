import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { Blog } from "../../components/definations";

interface DataState {
  newsAndInitiativesByTag: Record<number, Blog[]>;
  loading: boolean;
  error: any;
}
const initialState: DataState = {
  newsAndInitiativesByTag: {},
  loading: false,
  error: null,
};


export const fetchNewsInitiatives = createAsyncThunk(
  "newsAndInitiatives/fetchArrayData",
  async ({ tag }: { tag: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp/v2/posts?_embed&tags=${tag}`);
      const formattedData = response.data.map((item: any) => ({
        id: item.id,
        slug: item.slug,
        date: item?.date,
        title: item?.title.rendered,
        subTitle: item.excerpt?.rendered,
        src: item?.jetpack_featured_media_url,
        description: item?.content?.rendered,
        category: item?._embedded["wp:term"][0],
        comments: item._embedded["replies"]?.[0],
        link: item?.link
      }));

      // Update the state by tag
      dispatch(homeActions.dataFetched({ tag, data: formattedData }));
    } catch (error: any) {
      const errorResponse = error.response?.data || error.message;
      return rejectWithValue(errorResponse);
    }
  }
);

export const homeSlice = createSlice({
  name: "newsAndInitiatives",
  initialState,
  reducers: {
    dataFetched: (state, action: PayloadAction<{ tag: number, data: Blog[] }>) => {
      const { tag, data } = action.payload;
      state.newsAndInitiativesByTag[tag] = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsInitiatives.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNewsInitiatives.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchNewsInitiatives.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state) => state);
  },
});

export const homeActions = homeSlice.actions;
const homeReducer = homeSlice.reducer;

export default homeReducer;
