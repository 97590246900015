const authConfig = {
    Auth: {
        region:  process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
            scope: process.env.REACT_APP_AWS_OAUTH_SCOPE?.split(','),
            redirectSignIn: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT,
            responseType: process.env.REACT_APP_AWS_OAUTH_RESPONSE_TYPE
        }
    },
};

export default authConfig;
