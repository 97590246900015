import React, { useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";
import { RootState } from "../redux/store";
import "./style.scss";

const About = React.lazy(() => import("../views/About"));
const Contact = React.lazy(() => import("../views/Contact"));
const CommingSoon = React.lazy(() => import("../views/CommingSoon"));
const Home = React.lazy(() => import("../views/Home"));
const CaseStudies = React.lazy(() => import("../views/case-studies"));
const CaseStudiesDetails = React.lazy(
  () => import("../views/case-studies/Slug")
);
const Chatbox = React.lazy(() => import("../views/services/Chatbox"));
const DataArtificialIntelligence = React.lazy(() => import("../views/services/DataArtificialIntelligence"));
const DigitalStrategyConsulting = React.lazy(() => import("../views/services/DigitalStrategyConsulting"));
const TechnologyEngineering = React.lazy(() => import("../views/services/TechnologyEngineering"));
const PlatformingCloudServices = React.lazy(() => import("../views/services/PlatformingCloudServices"));
const Purpose = React.lazy(() => import("../views/Purpose"));
const RPDP = React.lazy(() => import("../views/solutions/RPDP"));
const ServicesIndex = React.lazy(() => import("../views/services/index"));
const Reports = React.lazy(() => import("../views/reports"));
const ReportDetails = React.lazy(() => import("../views/reports/Slug"));
const Privacy = React.lazy(() => import("../views/Privacy"));

const Router: React.FC = () => {
  const [transPage, setTransPage] = useState("in");
  const scrollbar = useSelector<RootState["scrollbar"]>(
    (state) => state.scrollbar
  ) as React.MutableRefObject<null | any>;

  const onAnimateEnd = () => {
    if (transPage !== "out") return;
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
    setTransPage("in");
  };

  const location = useEffectLocation(() => {
    setTransPage("out");
  });

  return (
    <div
      id="dsn-content"
      className={`dsn-transition-page dsn-animate-${transPage}`}
      onAnimationEnd={onAnimateEnd}
    >
      <Suspense fallback={<div className="background-main h-100-v" />}>
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<CommingSoon />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />

          {/* TODO: Slug */}
          <Route path="/services" element={<ServicesIndex />} />
          <Route path="/services/data-artificial-intelligence" element={<DataArtificialIntelligence />} />
          <Route path="/services/digital-strategy-consulting" element={<DigitalStrategyConsulting />} />
          <Route path="/services/technology-engineering" element={<TechnologyEngineering />} />
          <Route path="/services/platforming-cloud-services" element={<PlatformingCloudServices />} />
          <Route path="/chatbots" element={<Chatbox />} />
          <Route path="/services/chatbots" element={<Chatbox />} />

          <Route path="/rpdp-rapid-product-development-platform" element={<RPDP />} />
          <Route path="/purpose" element={<Purpose />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/:slug" element={<CaseStudiesDetails />} />

          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/:slug" element={<ReportDetails />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(Router);
