import { useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { Location } from 'history';

function useEffectLocation(effect: (location: Location) => void): Location {
    const location = useLocation();
    const [CLocation, setCLocation] = useState<Location | null>(location);

    const memoizedEffect = useCallback(() => {
        if (location.pathname === CLocation?.pathname) return;
        setCLocation(location);
        return effect(location);
    }, [location, CLocation?.pathname, effect]);

    useEffect(() => {
        return memoizedEffect();
    }, [location.pathname]);

    return location;
}

export default useEffectLocation;
